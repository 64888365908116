import React from 'react'
import { Container } from 'reactstrap'
import CareerRequirements from '../components/CareerRequiremetns/CareerRequirements'
import Layout from '../components/layout/layout'
import { Helmet } from "react-helmet"



const JobDescriptionFullStack = () => {
    return (
        <div>
            <Helmet title='DNNae | Full Stack JD' />
            <Layout
                containerStyle={{
                    position: 'relative'

                }}
                buttonStatus={false}
                selectedState=''
            >

                <Container
                    
                    style={{
                    maxWidth: '1920px'
                    }}
                >
                <CareerRequirements
                
                    
                    jobTitle='Full Stack Engineer'
                    jobTypeAndPlace='Contract, Islamabad'
                    weAreLooking='We are looking for a Full Stack Developer who will partner with both the business and technical teams to innovate and deliver new features and enhancements for our product. You will create and/or modify complex solutions that meet the expectations of users. You will also have a thorough knowledge of the entire system and work with product, engineering, QA and other teams in the organization to deliver the best experience to the users.
'
                    responsibilitiesArray={[
                        "Work with engineers and other cross-functional teams to develop innovative solutions that meet our needs",
                        'Ensure that technical solutions follow best practices, are reliable, are easily maintainable and are scalable under sustained load',
                        'Create and maintain application documentation as required',
                        'Find and help fix stability/scalability issues in a very time-critical environment',
                        'Support site rollouts and be available to resolve site issues',
                        'Develop industry and product technical expertise'
                    ]}
                    
                    whatYouNeedArray={[
                        '3+ years of experience working as a ReactJs Developer',
                        'Experience with JavaScript',
                        'You’ll have expert knowledge of JavaScript. You keep up with the latest developments but take a pragmatic approach to adopting tools and technology.',
                        'You should have a good working knowledge of using HTM5L, CSS3, SASS, rest API and deployment experience to create interfaces for your JavaScript applications. You also understand responsive design and cross-browser compatibility issues.',
                    ]}
                >

                </CareerRequirements>

                </Container>
            </Layout>
        </div>
    )
}

export default JobDescriptionFullStack
